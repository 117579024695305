import imageCompression from "browser-image-compression";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type FixImageSubmitType = {
  state: "submitting" | "idle";
  setState: (state: "submitting" | "idle") => void;
  onImageFixSubmit: (
    e: React.FormEvent<HTMLFormElement>,
  ) => Promise<FormData | null>;
};

export const FixImageSubmit = createContext<FixImageSubmitType>({
  state: "idle",
  setState: () => {},
  onImageFixSubmit: async () => null,
});

export function FixImageSubmitProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, setState] = useState<"submitting" | "idle">("idle");

  // File to base64
  const onImageFixSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
        setState("submitting");

        const form = e.currentTarget;
        const formData = new FormData(form);

        // image_files[i] の File を base64 に変換
        const imageFiles = Array.from(formData.entries())
          .filter(([name]) => name.startsWith("image_files["))
          .map(([, file]) => file)
          .filter((file) => file instanceof File && file.size > 0) as File[];
        const imageFilesBase64 = [];
        for (const file of imageFiles) {
          const base64 = await fileToBase64(file);
          imageFilesBase64.push(base64);
        }

        imageFilesBase64.forEach((base64, i) => {
          if (base64 !== null) {
            formData.set(`image_files[${i}]`, base64);
          }
        });

        // File を base64 に変換
        for (const i of ["avatar_file", "image_file"] as const) {
          const file = formData.get(i);
          if (file instanceof File && file.size > 0) {
            const base64 = await fileToBase64(file);
            if (base64 !== null) {
              formData.set(i, base64);
            }
          }
        }

        // Submit button intent
        const nativeEvent = e.nativeEvent as SubmitEvent;
        const submitter = nativeEvent.submitter as HTMLButtonElement | null;

        if (submitter) {
          formData.set(submitter.name, submitter.value);
        }

        setTimeout(() => setState("idle"), 500);
        return formData;
      } catch (error) {
        console.error(error);
      }

      setState("idle");
      return null;
    },
    [],
  );

  const contextValue = useMemo(
    () => ({ state, setState, onImageFixSubmit }),
    [state, setState, onImageFixSubmit],
  );

  return (
    <FixImageSubmit.Provider value={contextValue}>
      {children}
    </FixImageSubmit.Provider>
  );
}

export function useFixImageSubmit() {
  return useContext(FixImageSubmit);
}

export function decodeBase64Image(dataString: string) {
  const [filename, type, , base64] = dataString.split(",");
  const buffer = Buffer.from(base64, "base64");

  return new File([buffer], decodeURIComponent(filename), {
    type: decodeURIComponent(type),
  });
}

async function fileToBase64(file: File): Promise<string | null> {
  // Compress image
  const compressedFile = await imageCompression(file, {
    maxWidthOrHeight: 2048,
  });

  // Convert file to base64
  const reader = new FileReader();
  reader.readAsDataURL(compressedFile);
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(
        `${encodeURIComponent(file.name)},${encodeURIComponent(file.type)},${reader.result}`,
      );
    };
    reader.onerror = () => {
      reject(null);
    };
  });
}
